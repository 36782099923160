
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationAvatar from '@/modules/organization/components/dropdown/avatar.vue'

import { OrganizationModule } from '../../store'
import CreateDialog from '../create-dialog.vue'
import OrganizationListMenu from '../dropdown/list-menu.vue'

@Component({
  components: {
    CreateDialog,
    OrganizationAvatar,
    OrganizationListMenu
  },
  name: 'OrganizationAppbarList'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  organizationsVisible = false

  @Ref() readonly organizationButtonRef!: any
  @Ref() readonly organizationsListMenuRef!: OrganizationListMenu

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get loading () {
    return !this.currentOrganization
  }
}
