
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationAvatar from '@/modules/organization/components/dropdown/avatar.vue'
import VersionListMenu from '@/modules/version/components/dropdown/dropdown.vue'
import { VersionModule } from '@/modules/version/store'

import { OrganizationModule } from '../../store'
import CreateDialog from '../create-dialog.vue'
import OrganizationListMenu from '../dropdown/list-menu.vue'

@Component({
  components: {
    CreateDialog,
    OrganizationAvatar,
    OrganizationListMenu,
    VersionListMenu
  },
  name: 'OrganizationLandscapesAppbarList'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  landscapesVisible = false
  versionsVisible = false

  @Ref() readonly landscapeButtonRef!: any
  @Ref() readonly versionButtonRef!: any
  @Ref() readonly landscapesListMenuRef!: OrganizationListMenu
  @Ref() readonly versionsListMenuRef!: OrganizationListMenu

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions?.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get loading () {
    return !this.currentOrganization
  }
}
