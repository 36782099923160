
import { differenceInDays, differenceInHours } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'

import { OrganizationModule } from '../../store'

@Component({
  name: 'OrganizationAppbarUpgrade'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get button () {
    if (this.currentOrganization && this.currentOrganizationPermission && this.currentOrganizationPermission !== 'read' && this.currentOrganization.plan === 'free') {
      if (!this.currentOrganization.trialEndsAt) {
        return {
          icon: '$fas-bolt',
          text: 'Free trial'
        }
      } else {
        return {
          icon: '$fas-bolt',
          text: 'Upgrade'
        }
      }
    }
  }

  get showTrial () {
    return this.currentOrganizationPermission &&
      this.currentOrganizationPermission !== 'read' &&
      this.currentOrganization &&
      this.currentOrganization.status === 'trialing'
  }

  get trialHoursLeft () {
    return this.currentOrganization?.trialEndsAt ? differenceInHours(new Date(this.currentOrganization.trialEndsAt), new Date()) : 0
  }

  get trialDaysLeft () {
    return this.currentOrganization?.trialEndsAt ? differenceInDays(new Date(this.currentOrganization.trialEndsAt), new Date()) : 0
  }

  get trialColor () {
    if (this.trialHoursLeft <= 24) {
      return 'error'
    } else if (this.trialHoursLeft <= 4 * 24) {
      return 'info'
    } else {
      return 'accent'
    }
  }

  get trialValue () {
    return (this.trialHoursLeft / (24 * 14)) * 100
  }
}
