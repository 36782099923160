
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationCreateDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: [
        ...validationRules.exists,
        ...validationRules.maxLength(128)
      ]
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get organizationsExist () {
    return !!this.organizationModule.organizations.length
  }

  created () {
    this.formController.submitHandler = async model => {
      const organization = await this.organizationModule.organizationCreate({
        name: model.name
      })

      analytics.organizationCreate.track(this, {
        organizationBillingCurrency: organization.billingCurrency,
        organizationBillingCycle: organization.billingCycle,
        organizationBillingEmail: organization.billingEmail,
        organizationId: [organization.id],
        organizationName: organization.name,
        organizationPlan: organization.plan,
        organizationSeats: organization.seats,
        organizationStatus: organization.status
      })

      await this.$router.push({
        name: 'landscapes',
        params: {
          organizationId: organization.id
        }
      })
    }
  }

  opened () {
    analytics.organizationCreateDialog.track(this, {
      organizationId: [this.currentOrganizationId]
    })
  }

  closed () {
    this.formController.resetModel()
    this.formController.resetStatus()
  }
}
